<template>
  <div>
    <Toolbar class="mb-4">
      <template #right>Neue hinzufügen</template>
      <template #left>
        <Button icon="pi pi-times" class="p-button-danger" @click="$router.push('/admin/users')" />
      </template>
    </Toolbar>
    <div class="form-card row">
      <div class="col-md-12 text-center">
        <img :src="body.image" v-if="id" class="profile-pic height-150 width-150 align-items-center"
          style="width: 100px; height: 100px" />
        <!-- @error="$errorHandler" -->
      </div>
      <div class="col-md-6">
        <div class="mb-3">
          <label for="fullName" class="form-label required">
            Name
            <span>*</span>
          </label>
          <input type="text" class="form-control" id="fullName" v-model="body.fullName"
            :class="{ 'is-invalid': isInvalid && !body.fullName }" required />
        </div>
      </div>
      <div class="col-md-6">
        <div class="mb-3">
          <label for="email" class="form-label required">
            Email
            <span>*</span>
          </label>
          <input type="email" class="form-control" id="email" v-model="body.email"
            :class="{ 'is-invalid': isInvalid && !body.email }" required />
        </div>
      </div>

      <div class="col-md-6">
        <div class="mb-3">
          <label for="phone" class="form-label required">
            Telefon
            <span>*</span>
          </label>
          <input type="tel" class="form-control" id="phone" v-model.trim="body.phone"
            :class="{ 'is-invalid': isInvalid && !body.phone }" required />
        </div>
      </div>

      <div class="col-md-6">
        <div class="mb-3">
          <label for="password" class="form-label required">
            Passwort
            <span>*</span>
          </label>
          <input type="password" class="form-control" id="password" v-model="body.password"
            :class="{ 'is-invalid': isInvalid && !body.password }" required />
        </div>
      </div>

      <div class="col-md-6">
        <div class="mb-3">
          <label for="workHours" class="form-label required">
            Arbeitsstunden
            <span>*</span>
          </label>
          <input type="number" class="form-control" id="workHours" v-model.number="body.workHours"
            :class="{ 'is-invalid': isInvalid && !body.workHours }" required />
        </div>
      </div>

      <div class="col-md-6">
        <div class="mb-3">
          <label for="holydays" class="form-label required">
            Urlaub
            <span>*</span>
          </label>
          <input type="number" class="form-control" id="holydays" v-model.number="body.holydays"
            :class="{ 'is-invalid': isInvalid && !body.holydays }" required />
        </div>
      </div>

      <div class="col-md-6">
        <div class="mb-3">
          <label for="role" class="form-label required">
            Rollen
            <span>*</span>
          </label>

          <select class="form-select form-control" id="role" name="role" v-model.number="body.role"
            :class="{ 'is-invalid': isInvalid && !body.role }" required>
            <option value="admin">Administrator</option>
            <option value="massager">Massagegerät</option>
          </select>
        </div>
      </div>

      <div class="col-md-6">
        <div class="mb-3">
          <label for="group_id" class="form-label required">
            Bild
            <span>*</span>
          </label>

          <div class="align-items-center">
            <div class="profile-img-edit align-items-center">
              <div class="p-image">
                <div class="position-relative">
                  <i class="ri-pencil-line upload-button"></i>
                  <b-form-file class="h-100 position-absolute" accept="image/*" @change="previewImage"></b-form-file>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="col-md-4">
        <div class="mb-3">
          <label for="Saturday" class="form-label required"> Saturday </label>

          <SelectButton v-model="body.Saturday" :options="optionsList" optionValue="value" optionLabel="name" />
        </div>
      </div>
      <div class="col-md-4">
        <div class="mb-3">
          <label for="SaturdayStart" class="form-label required">
            Saturday Start
          </label>
          <Dropdown style="width: 100%" :options="timeList" optionLabel="value" optionValue="value"
            v-model="body.SaturdayStart" />
        </div>
      </div>
      <div class="col-md-4">
        <div class="mb-3">
          <label for="Saturday End" class="form-label required">
            Saturday End
          </label>
          <Dropdown style="width: 100%" :options="timeList" optionLabel="value" optionValue="value"
            v-model="body.SaturdayEnd" />
        </div>
      </div>

      <div class="col-md-12">
        <hr />
        <br />
      </div>

      <div class="col-md-4">
        <div class="mb-3">
          <label for="Sunday" class="form-label required"> Sunday </label>

          <SelectButton v-model="body.Sunday" :options="optionsList" optionValue="value" optionLabel="name" />
        </div>
      </div>
      <div class="col-md-4">
        <div class="mb-3">
          <label for="SundayStart" class="form-label required">
            Sunday Start
          </label>
          <Dropdown style="width: 100%" :options="timeList" optionLabel="value" optionValue="value"
            v-model="body.SundayStart" />
        </div>
      </div>
      <div class="col-md-4">
        <div class="mb-3">
          <label for="Sunday End" class="form-label required">
            Sunday End
          </label>
          <Dropdown style="width: 100%" :options="timeList" optionLabel="value" optionValue="value"
            v-model="body.SundayEnd" />
        </div>
      </div>

      <div class="col-md-12">
        <hr />
        <br />
      </div>

      <div class="col-md-4">
        <div class="mb-3">
          <label for="Monday" class="form-label required"> Monday </label>

          <SelectButton v-model="body.Monday" :options="optionsList" optionValue="value" optionLabel="name" />
        </div>
      </div>

      <div class="col-md-4">
        <div class="mb-3">
          <label for="MondayStart" class="form-label required">
            Monday Start
          </label>
          <Dropdown style="width: 100%" :options="timeList" optionLabel="value" optionValue="value"
            v-model="body.MondayStart" />
        </div>
      </div>

      <div class="col-md-4">
        <div class="mb-3">
          <label for="Monday End" class="form-label required">
            Monday End
          </label>
          <Dropdown style="width: 100%" :options="timeList" optionLabel="value" optionValue="value"
            v-model="body.MondayEnd" />
        </div>
      </div>

      <div class="col-md-12">
        <hr />
        <br />
      </div>

      <!-- mond -->

      <div class="col-md-4">
        <div class="mb-3">
          <label for="Tuesday" class="form-label required"> Tuesday </label>

          <SelectButton v-model="body.Tuesday" :options="optionsList" optionValue="value" optionLabel="name" />
        </div>
      </div>
      <div class="col-md-4">
        <div class="mb-3">
          <label for="TuesdayStart" class="form-label required">
            Tuesday Start
          </label>
          <Dropdown style="width: 100%" :options="timeList" optionLabel="value" optionValue="value"
            v-model="body.TuesdayStart" />
        </div>
      </div>
      <div class="col-md-4">
        <div class="mb-3">
          <label for="Tuesday End" class="form-label required">
            Tuesday End
          </label>
          <Dropdown style="width: 100%" :options="timeList" optionLabel="value" optionValue="value"
            v-model="body.TuesdayEnd" />
        </div>
      </div>

      <div class="col-md-12">
        <hr />
        <br />
      </div>

      <!-- tus -->

      <div class="col-md-4">
        <div class="mb-3">
          <label for="Wednesday" class="form-label required"> Wednesday </label>

          <SelectButton :options="optionsList" v-model="body.Wednesday" optionValue="value" optionLabel="name" />
        </div>
      </div>
      <div class="col-md-4">
        <div class="mb-3">
          <label for="WednesdayStart" class="form-label required">
            Wednesday Start
          </label>
          <Dropdown style="width: 100%" :options="timeList" optionLabel="value" optionValue="value"
            v-model="body.WednesdayStart" />
        </div>
      </div>
      <div class="col-md-4">
        <div class="mb-3">
          <label for="Wednesday End" class="form-label required">
            Wednesday End
          </label>
          <Dropdown style="width: 100%" :options="timeList" optionLabel="value" optionValue="value"
            v-model="body.WednesdayEnd" />
        </div>
      </div>

      <div class="col-md-12">
        <hr />
        <br />
      </div>

      <!-- wed -->

      <div class="col-md-4">
        <div class="mb-3">
          <label for="Thursday" class="form-label required"> Thursday </label>

          <SelectButton v-model="body.Thursday" :options="optionsList" optionValue="value" optionLabel="name" />
        </div>
      </div>
      <div class="col-md-4">
        <div class="mb-3">
          <label for="ThursdayStart" class="form-label required">
            Thursday Start
          </label>
          <Dropdown style="width: 100%" :options="timeList" optionLabel="value" optionValue="value"
            v-model="body.ThursdayStart" />
        </div>
      </div>
      <div class="col-md-4">
        <div class="mb-3">
          <label for="Thursday End" class="form-label required">
            Thursday End
          </label>
          <Dropdown style="width: 100%" :options="timeList" optionLabel="value" optionValue="value"
            v-model="body.ThursdayEnd" />
        </div>
      </div>

      <div class="col-md-12">
        <hr />
        <br />
      </div>

      <!--  -->

      <div class="col-md-4">
        <div class="mb-3">
          <label for="Friday" class="form-label required"> Friday </label>

          <SelectButton v-model="body.Friday" :options="optionsList" optionValue="value" optionLabel="name" />
        </div>
      </div>
      <div class="col-md-4">
        <div class="mb-3">
          <label for="FridayStart" class="form-label required">
            Friday Start
          </label>
          <Dropdown style="width: 100%" :options="timeList" optionLabel="value" optionValue="value"
            v-model="body.FridayStart" />
        </div>
      </div>
      <div class="col-md-4">
        <div class="mb-3">
          <label for="Friday End" class="form-label required">
            Friday End
          </label>
          <Dropdown style="width: 100%" :options="timeList" optionLabel="value" optionValue="value"
            v-model="body.FridayEnd" />
        </div>
      </div>

      <div class="col-md-12">
        <hr />
        <br />
      </div>

      <!--  -->

      <div class="col-md-6">
        <div class="mb-3">
          <label for="holdFrom" class="form-label"> Hold From </label>

          <Calendar style="width: 100%" v-model="body.holdFrom" :class="{ 'is-invalid': isInvalid && !body.holdFrom }"
            dateFormat="dd-mm-yy" :minDate="minDateValue" />
        </div>
      </div>

      <div class="col-md-6">
        <div class="mb-3">
          <label for="hold" class="form-label"> Hold To </label>

          <Calendar style="width: 100%" v-model="body.holdTo" :class="{ 'is-invalid': isInvalid && !body.holdTo }"
            dateFormat="dd-mm-yy" :minDate="minDateValue" />
        </div>
      </div>

      <!-- <div class="col-md-2">
        <div class="mb-3">
          <label for="hold" class="form-label ">
            Color
          </label>
          <input type="color" v-model="body.color" />
        </div>
      </div> -->

      <div class="col-md-12">
        <div class="mb-3">
          <label for="inhomepage" class="form-label required">Körper</label>

          <Editor v-model="body.userNotes" editorStyle="height: 320px" />
        </div>
      </div>
      <div class="col-md-12">
        <div class="mb-3 text-center">
          <Button label="Bearbeiten" icon="pi pi-pencil" class="ml-2 p-button-info" @click="update()" v-if="id"
            :disabled="disabledAdd" />
          <Button v-else label="Hinzufügen" icon="pi pi-plus" class="ml-2 p-button-success" @click="save()"
            :disabled="disabledAdd" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    const d = new Date();
    const ye = new Intl.DateTimeFormat("en", { year: "numeric" }).format(d);
    const mo = new Intl.DateTimeFormat("en", { month: "2-digit" }).format(d);
    const da = new Intl.DateTimeFormat("en", { day: "2-digit" }).format(d);
    return {
      minDateValue: new Date(`${ye}-${mo}-${da}`),
      timeList: [
        { value: "08:00" },
        { value: "08:15" },
        { value: "08:30" },
        { value: "08:45" },
        { value: "09:00" },
        { value: "09:15" },
        { value: "09:30" },
        { value: "09:45" },
        { value: "10:00" },
        { value: "10:15" },
        { value: "10:30" },
        { value: "10:45" },
        { value: "11:00" },
        { value: "11:15" },
        { value: "11:30" },
        { value: "11:45" },
        { value: "12:00" },
        { value: "12:15" },
        { value: "12:30" },
        { value: "12:45" },
        { value: "13:00" },
        { value: "13:15" },
        { value: "13:30" },
        { value: "13:45" },
        { value: "14:00" },
        { value: "14:15" },
        { value: "14:30" },
        { value: "14:45" },
        { value: "15:00" },
        { value: "15:15" },
        { value: "15:30" },
        { value: "15:45" },
        { value: "16:00" },
        { value: "16:15" },
        { value: "16:30" },
        { value: "16:45" },
        { value: "17:00" },
        { value: "17:15" },
        { value: "17:30" },
        { value: "17:45" },
        { value: "18:00" },
        { value: "18:15" },
        { value: "18:30" },
        { value: "18:45" },
        { value: "19:00" },
        { value: "19:15" },
        { value: "19:30" },
        { value: "19:45" },
        { value: "20:00" },
        { value: "20:15" },
        { value: "20:30" },
        { value: "20:45" },
        { value: "21:00" },
        { value: "22:00" },
      ],
      body: {
        image: null,
        holdFrom: null,
        holdTo: null,
        email: null,
        password: null,
        workHours: 30,
        holydays: 20,
        phone: null,
        role: "admin",
        fullName: null,
        Saturday: 0,
        Sunday: 0,
        Monday: 0,
        Tuesday: 0,
        Wednesday: 0,
        Thursday: 0,
        Friday: 0,
        SaturdayStart: "08:00",

        SundayStart: "08:00",
        MondayStart: "08:00",
        TuesdayStart: "08:00",
        WednesdayStart: "08:00",
        ThursdayStart: "08:00",
        FridayStart: "08:00",
        SaturdayEnd: "22:00",
        SundayEnd: "22:00",
        MondayEnd: "22:00",
        TuesdayEnd: "22:00",
        WednesdayEnd: "22:00",
        ThursdayEnd: "22:00",
        FridayEnd: "22:00",
        userNotes: null


      },
      id: null,

      optionsList: [
        { name: "Off", value: false },
        { name: "On", value: true },
      ],



      isInvalid: false,
      disabledAdd: false,
    };
  },
  methods: {
    previewImage(ev) {
      this.$file2base64(ev, (image) => {
        this.body.image = image;
      });
    },
    save() {
      if (
        this.body.image &&
        this.body.password &&
        this.body.role &&
        this.body.fullName &&
        this.body.phone
      ) {
        this.disabledAdd = true;

        this.$http.post(`admins`, this.body).then(
          () => {
            this.$toast.add({
              severity: "success",
              summary: "Done Successfully",
              detail: "Added Successfully",
              life: 3000,
            });
            this.disabledAdd = false;

            this.$router.push("/admin/users");
          },
          (err) => {
            this.disabledAdd = false;

            this.$toast.add({
              severity: "error",
              summary: "Error",
              detail: err.response.data.message,
              life: 3000,
            });
          }
        );
      } else {
        this.isInvalid = true;
        this.$toast.add({
          severity: "error",
          summary: "Error",
          detail: "Please add the required fields",
          life: 3000,
        });
      }
    },
    update() {
      if (
        // this.body.userName &&
        this.body.image &&
        this.body.role &&
        this.body.fullName &&
        this.body.phone
      ) {
        this.disabledAdd = true;

        if (this.body.password == null) {
          delete this.body.password;
        }
        if (this.body.image.startsWith("http")) {
          delete this.body.image;
        }
        delete this.body.massageList;
        this.$http.put(`admins/${this.id}`, this.body).then(
          () => {
            this.$toast.add({
              severity: "success",
              summary: "Done Successfully",
              detail: "Updated Successfully",
              life: 3000,
            });
            this.disabledAdd = false;

            this.$router.push("/admin/users");
          },
          (err) => {
            this.disabledAdd = false;

            this.$toast.add({
              severity: "error",
              summary: "Error",
              detail: err.response.data.message,
              life: 3000,
            });
          }
        );
      } else {
        this.isInvalid = true;
        this.$toast.add({
          severity: "error",
          summary: "Error",
          detail: "Please add the required fields",
          life: 3000,
        });
      }
    },
  },
  created() {
    if (this.$route.params.id) {
      this.id = this.$route.params.id;
      this.$http.get(`admins/${this.id}`).then(
        (res) => {
          this.body = res.data.data;
          this.body.holdFrom = null;
          this.body.holdTo = null;
          this.body.image = this.$baseUploadURL + this.body.image;
        },
        (err) => {
          this.$toast.add({
            severity: "error",
            summary: "Error",
            detail: err.response.data.message,
            life: 3000,
          });
        }
      );
    }
  },
};
</script>

<style>
.branches label {
  display: inline;
  text-align: start !important;
  margin-right: 11px;
}
</style>
